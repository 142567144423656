import React from 'react';
import { useOrdersState } from "../../../state/index";

interface Item {
    MTRL: string;
    VATVAL: string;
    CODE1: string;
    QTY1: string;
    PRET: string;
    LINENUM: string;
    NUME: string;
}

interface SelectedOrder {
    BRANCH: {
        CUSBRANCH: string;
    };
    ITELINES: Item[];
    TOTAL: Item[];
}

const View: React.FC = () => {
    const { selectedOrder, judete } = useOrdersState();

    const typedSelectedOrder = selectedOrder as SelectedOrder;
    const metodaPlata = typedSelectedOrder?.SALDOC?.PAYNAME;
    const nrAwb = typedSelectedOrder?.SALDOC?.AWB_CODE?.[0];
    const discount = typedSelectedOrder?.TOTAL?.DISC1VAL;
    const transport = typedSelectedOrder?.SRVLINES?.[0]?.PRET;
    const totalFaraTVA = typedSelectedOrder?.TOTAL?.TOTALFARATVA;
    const totalVAT = typedSelectedOrder?.TOTAL?.TOTALVAT;
    const total = typedSelectedOrder?.TOTAL?.TOTAL;

    return (
        <div>
            <div className="order-info">
                <h2>Comanda #</h2>
                <h3>{selectedOrder.SALDOC.FINDOC}</h3>
                <span> / </span>
                <h3>{selectedOrder.SALDOC.CCCFINCODE1}</h3>
            </div>
            <div className="order-info">
                <p className="data-plasare-comanda">Data plasare comanda: {selectedOrder.SALDOC.TRNDATE}</p>
                <span> / </span>
                <p className="status-comanda">Status comanda: {selectedOrder.SALDOC.STATUSNAMELIVRARE}</p>
            </div>

            <div className="customer-data">
                <div className="address-data">
                    <h3>Adresa facturare</h3>
                    <p>
                        <label>SoftOne ID: </label>
                        <span>{typedSelectedOrder?.BILLING?.TRDBRANCH}</span>
                    </p>
                    <p>
                        <label>Name: </label>
                        <span>{typedSelectedOrder?.BILLING?.NAME}</span>
                    </p>
                    <p>
                        <label>District: </label>
                        <span>{judete[typedSelectedOrder?.BILLING?.REGION] ?? 'N/A'}</span>
                    </p>
                    <p>
                        <label>City: </label>
                        <span>{typedSelectedOrder?.BILLING?.CITY}</span>
                    </p>
                    <p>
                        <label>Address: </label>
                        <span>{typedSelectedOrder?.BILLING?.ADDRESS}</span>
                    </p>
                    <p>
                        <label>Phone: </label>
                        <span>{typedSelectedOrder?.BILLING?.PHONE1}</span>
                    </p>
                    <p>
                        <label>Email: </label>
                        <span>{typedSelectedOrder?.BILLING?.EMAIL}</span>
                    </p>
                </div>
                <div className="address-data">
                    <h3>Adresa livrare</h3>
                    <p>
                        <label>SoftOne ID: </label>
                        <span>{typedSelectedOrder?.SHIPPING?.TRDBRANCH}</span>
                    </p>
                    <p>
                        <label>Name: </label>
                        <span>{typedSelectedOrder?.SHIPPING?.NAME}</span>
                    </p>
                    <p>
                        <label>District: </label>
                        <span>{judete[typedSelectedOrder?.SHIPPING?.REGION] ?? 'N/A'}</span>
                    </p>
                    <p>
                        <label>City: </label>
                        <span>{typedSelectedOrder?.SHIPPING?.CITY}</span>
                    </p>
                    <p>
                        <label>Address: </label>
                        <span>{typedSelectedOrder?.SHIPPING?.ADDRESS}</span>
                    </p>
                    <p>
                        <label>Phone: </label>
                        <span>{typedSelectedOrder?.SHIPPING?.PHONE1}</span>
                    </p>
                    <p>
                        <label>Email: </label>
                        <span>{typedSelectedOrder?.SHIPPING?.EMAIL}</span>
                    </p>
                </div>

                <div className="address-data">
                    <h3>Client</h3>
                    <p>
                        <label>SoftOne ID: </label>
                        <span>{typedSelectedOrder?.CUSTOMER?.TRDR}</span>
                    </p>
                    <p>
                        <label>Name: </label>
                        <span>{typedSelectedOrder?.CUSTOMER?.NUME}</span>
                    </p>
                    <p>
                        <label>District: </label>
                        <span>{judete[typedSelectedOrder?.CUSTOMER?.REGION] ?? 'N/A'}</span>
                    </p>
                    <p>
                        <label>City: </label>
                        <span>{typedSelectedOrder?.CUSTOMER?.CITY}</span>
                    </p>
                    <p>
                        <label>Address: </label>
                        <span>{typedSelectedOrder?.CUSTOMER?.ADDRESS}</span>
                    </p>
                    <p>
                        <label>Phone: </label>
                        <span>{typedSelectedOrder?.CUSTOMER?.PHONE1}</span>
                    </p>
                    <p>
                        <label>Email: </label>
                        <span>{typedSelectedOrder?.CUSTOMER?.EMAIL}</span>
                    </p>
                </div>
            </div>
            <h3>Informatii produse</h3>
            <table className="frontend-data-grid">
                <thead>
                <tr>
                    <th className="items-data view-data data-grid-th">Cod produs</th>
                    <th className="items-data view-data data-grid-th">Denumire produs</th>
                    <th className="items-data view-data data-grid-th">Cantitate</th>
                    <th className="items-data view-data data-grid-th">Pret/buc</th>
                    <th className="items-data view-data data-grid-th">Subtotal</th>
                </tr>
                </thead>
                {typedSelectedOrder.ITELINES.map((item, index) => (
                    <tbody>
                    <tr>
                        <td>
                            <span>{item.CODE1}</span>
                        </td>
                        <td>
                            <span>{item.NUME}</span>
                        </td>
                        <td>
                            <span>{item.QTY1}</span>
                        </td>
                        <td>
                            <span>{item.PRET} {' lei'}</span>
                        </td>
                        <td>
                            <span>{item.PRET * item.QTY1} {' lei'}</span>
                        </td>
                    </tr>
                    </tbody>
                ))}
            </table>
            <div className="data-grid sumar-factura">
                <h4>Sumar comanda</h4>
                <p className="items-data">
                    Modalitate de plata:
                    <span>{metodaPlata}</span>
                </p>
                {nrAwb && (
                    <p className="items-data">
                        Metoda de expediere:
                        <span>Curier national ({'numar AWB: '}{nrAwb})</span>
                    </p>
                )}
                {discount > 0 && (
                    <p className="items-data">
                        Discount:
                        <span>{discount} {' lei'}</span>
                    </p>
                )}
                {transport > 0 && (
                    <p className="items-data">
                        Cost transport:
                        <span>{transport} {' lei'}</span>
                    </p>
                )}
                <p className="items-data">
                    Total fara TVA
                    <span>{totalFaraTVA} {' lei'}</span>
                </p>
                <p className="items-data">
                    TVA
                    <span>{totalVAT} {' lei'}</span>
                </p>
                <p className="items-data total">
                    Total:
                    <span>{total} {' lei'}</span>
                </p>
            </div>
        </div>
    );
};

export default View;
