import React, {useState} from 'react';
import {OrdersProvider, useOrdersState} from './../../state/index';
import OrdersTable from './Table/OrdersTable';
import OrderDetails from "./OrderDetails";

interface AppConfig {
    config: {
        isLoggedIn: boolean;
    };
}

interface OrdersAppProps {
    config: AppConfig;
}

export const OrdersAppInner: React.FC<OrdersAppProps> = ({ config }) => {
    const isLoggedIn = config.config.isLoggedIn;

    const {isLoading, handleOrderDownload, handleOrderClick, selectedOrder, handleBack} = useOrdersState();
    const [downloadingOrderId, setDownloadingOrderId] = useState(null);

    if (!isLoggedIn) {
        return (
            <div className="message info empty">
                <p>Please log in to view your orders.</p>
                <a href="/customer/account/login" className="action primary">
                    <span style={{ color: 'white' }} >Login</span>
                </a>
            </div>
        )
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'ERP ID',
                accessor: 'col1',
            },
            {
                Header: 'ID Comanda',
                accessor: 'col2',
            },
            {
                Header: 'Client',
                accessor: 'col3',
            },
            {
                Header: 'Data Comanda',
                accessor: 'col4',
            },
            {
                Header: 'Total cu TVA',
                accessor: 'col5',
            },
            {
                Header: 'Status',
                accessor: 'col6',
            },
            {
                Header: 'Numar AWB',
                accessor: 'col7',
                Cell: ({cell: {value}}) => (
                    <div dangerouslySetInnerHTML={{__html: value}}/>
                ),
            },

            {
                Header: 'Vezi comanda',
                Cell: ({row}) => (
                    <>
                        <button className="action-default" onClick={() => handleOrderClick(row.original.order)}>View
                        </button>
                    </>
                ),
            },
            {
                Header: 'Download Factura',
                Cell: ({row}) => {
                    const orderId = row.original.col1;
                    const orderStatus = row.original.order.SALDOC.STATUSNAMELIVRARE;
                    const isDownloading = downloadingOrderId === orderId;

                    const canDownloadInvoice = orderStatus === 'Complet';

                    if (!canDownloadInvoice) {
                        return <span>Factura nu a fost emisă</span>;
                    }

                    return (
                        <button
                            className="action-default"
                            onClick={() => {
                                setDownloadingOrderId(orderId);
                                handleOrderDownload(orderId)
                                    .then(() => {
                                        console.log('Download successful');
                                    })
                                    .catch(error => {
                                        console.error('Download failed:', error);
                                    })
                                    .finally(() => {
                                        setDownloadingOrderId(null);
                                    });
                            }}
                            disabled={isDownloading}
                        >
                            {isDownloading ? 'Așteptați...' : 'Download'}
                        </button>
                    );
                },
            },
        ],
        [downloadingOrderId, handleOrderDownload]
    );

    return (
        <div>
            {isLoading && <div className="loading-overlay">Loading...</div>} {/*Loading overlay*/}

            {!selectedOrder && (
                <OrdersTable
                    columns={columns}
                />
            )}
            {selectedOrder && (
                <OrderDetails
                    order={selectedOrder}
                    onBack={handleBack}
                />
            )}
        </div>
    );
};

export const OrdersApp: React.FC<OrdersAppProps> = ({ config }) => {
    return (
        <OrdersProvider config={config}>
            <OrdersAppInner config={config} />
        </OrdersProvider>
    );
}
