import React, {ChangeEvent, createContext, useContext, useEffect, useState} from 'react';
import $ from 'jquery';

interface OrdersProviderProviderType {
    config: any;
    orders: any[];
    totalOrders: number;
    totalRows: number;
    handleOrderDownload: any;
    handleBack: any;
    selectedOrder: any;
    handleSearchIncrementId: any
    handlePageChange: any
    data: any
    setFilterSoftOneId: any
    currentPage: any
    handleStartDateChange: any
    handleEndDateChange: any
    filterStartDate: any
    filterEndDate: any
    handleFetchOrdersClick: any
    isLoading: any,
    removePrefixes: any,
    handleOrderClick,
    judete: any,
    handleCancelOrder: any,
}

interface OrdersProviderPropsType {
    children: React.ReactNode;
    config: any;
}

const OrdersStateProvider = createContext<OrdersProviderProviderType>(null);

export const OrdersProvider = ({children, config}: OrdersProviderPropsType) => {
    const [orders, setOrders] = useState([]);
    const [totalOrders, setTotalOrders] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [judete, setJudete] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [filterSoftOneId, setFilterSoftOneId] = useState('');
    const [searchIncrementId, setSearchIncrementId] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    const [filterStartDate, setFilterStartDate] = useState('');
    const [filterEndDate, setFilterEndDate] = useState('');
    const [params, setParams] = useState({
        filters: {
            startDate: filterStartDate ?? null,
            endDate: filterEndDate ?? null
        }
    });

    const [isInitialLoad, setIsInitialLoad] = useState(true);

    useEffect(() => {
        const today = new Date();
        const thirtyDaysAgo = new Date(today.getTime() - (30 * 24 * 60 * 60 * 1000));

        setFilterStartDate(thirtyDaysAgo.toISOString().split('T')[0]);
        setFilterEndDate(today.toISOString().split('T')[0]);
    }, []);

    const fetchOrders = (params = {}) => {
        setIsLoading(true)
        $.getJSON(BASE_URL + 'react_so/index/getdata', params, (response) => {
            const {DATA: data, success, totalOrders, totalRows, judete} = response;
            if (success) {
                setOrders(data.reverse());
                setTotalOrders(totalOrders);
                setJudete(judete);
                setTotalRows(totalRows);
            }

            setIsLoading(false)
        });
    };

    const handleOrderDownload = (id) => {
        return new Promise((resolve, reject) => {
            $.getJSON(BASE_URL + 'react_so/index/download', {order_id: id})
                .done(function(response) {
                    if (response.success) {
                        var link = document.createElement('a');
                        link.href = response.url;
                        link.target = '_blank';
                        link.style.display = 'none';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        resolve(response);
                    } else {
                        console.log('Download failed:', response);
                        reject(response);
                    }
                })
                .fail(function(error) {
                    console.log('Something went wrong!');
                    reject(error);
                });
        });
    };

    const handleBack = () => {
        setSelectedOrder(null);
    };

    const data = orders
        .filter((order) => {
            return (!filterSoftOneId || order.SALDOC.FINDOC.toString().toLowerCase().includes(filterSoftOneId.toLowerCase())) &&
                (!searchIncrementId || order.SALDOC.CCCFINCODE1.toString().toLowerCase().includes(searchIncrementId.toLowerCase()));
        })
        .map((order) => {
            const awb_code = Array.isArray(order.SALDOC.AWB_CODE) ?
                order.SALDOC.AWB_CODE :
                [order.SALDOC.AWB_CODE];

            const awb_links = awb_code.map(code =>
                `<a href='https://xawb.ro/fan-courier/${code}' target='_blank' rel='noopener noreferrer'>${code}</a>`
            ).join(', ');

            return {
                col1: order.SALDOC.FINDOC,
                col2: order.SALDOC.CCCFINCODE1,
                col3: order.CUSTOMER.NUME,
                col4: order.SALDOC.TRNDATE,
                col5: order.TOTAL.TOTAL,
                col6: order.SALDOC.STATUSNAMELIVRARE,
                col7: awb_links,
                order,
            };
        });

    const handleSearchIncrementId = (searchTerm) => {
        setSearchIncrementId(searchTerm);
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    useEffect(() => {
        if (isInitialLoad && filterStartDate && filterEndDate) {
            fetchOrders({
                filters: {
                    fromDate: filterStartDate,
                    toDate: filterEndDate,
                }
            });
            setIsInitialLoad(false);
        }
    }, [isInitialLoad, filterStartDate, filterEndDate, fetchOrders]);

    const handleStartDateChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFilterStartDate(e.target.value);
        if (filterEndDate && new Date(e.target.value) > new Date(filterEndDate)) {
            setFilterEndDate(e.target.value);
        }
    };

    const handleEndDateChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (new Date(e.target.value) >= new Date(filterStartDate)) {
            setFilterEndDate(e.target.value);
        } else {
            alert('End date cannot be before start date.');
        }
    };

    const handleOrderClick = (order) => {
        setSelectedOrder(order);
    };

    const handleFetchOrdersClick = () => {
        fetchOrders({
            filters: {
                fromDate: filterStartDate ?? null,
                toDate: filterEndDate ?? null,
                findoc: filterSoftOneId,
                fincode1: searchIncrementId,
            }
        });
    };

    const removePrefixes = (data) => {
        const result = {};

        Object.keys(data).forEach((key) => {
            const newKey = key.substring(key.indexOf('.') + 1); // Remove prefix
            result[newKey] = data[key];
        });

        return result;
    }

    const handleCancelOrder = () => {
        cancelOrders({
            orderId:selectedOrder.SALDOC.FINDOC
        });
    };

    const cancelOrders = (params = {}) => {
        setIsLoading(true)
        setParams(params)
        return $.getJSON(BASE_URL + 'react_so/index/cancelOrder', params, (response) => {

            const {DATA: success} = response;
            if (success) {

            }

            setIsLoading(false)
        });
    };

    return (
        <OrdersStateProvider.Provider
            value={{
                config,
                orders,
                totalOrders,
                totalRows,
                handleOrderDownload,
                handleBack,
                selectedOrder,
                handleSearchIncrementId,
                handlePageChange,
                currentPage,
                data,
                setFilterSoftOneId,
                handleStartDateChange,
                handleEndDateChange,
                filterStartDate,
                filterEndDate,
                handleFetchOrdersClick,
                isLoading,
                removePrefixes,
                handleOrderClick,
                judete,
                handleCancelOrder
            }}
        >
            {children}
        </OrdersStateProvider.Provider>
    );
};

export const useOrdersState = () => {
    const context = useContext(OrdersStateProvider);

    if (!context) {
        console.warn(`useOrdersState must be used within the OrdersStateProvider`);
    }

    return context;
};
